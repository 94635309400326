import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  max-width: 800px;
`

const PageWrapper = styled(Container)`
  flex: 1;
  justify-content: center;
  padding: 50px;
  text-align: justify;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    justify-content: space-between;
    padding-top: 64px;
  }
`

const Title = styled(ThemedText.HeadlineLarge).attrs((props) => ({
  style: {
    fontWeight: 500,
  },
}))``

const Subheader = styled(ThemedText.HeadlineMedium).attrs((props) => ({
  style: {
    fontWeight: 800,
    lineHeight: '2rem',
  },
}))``

const Subtext = styled(ThemedText.SubHeader).attrs((props) => ({
  style: {
    fontWeight: 500,
    fontStyle: 'italic',
    lineHeight: '3rem',
  },
}))``

const Paragraph = styled(ThemedText.BodyPrimary).attrs((props) => ({
  style: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '1.5rem',
  },
}))``

const Link = styled.a`
  color: ${({ theme }) => theme.textPrimary};
`

const List = styled.ul`
  list-style-type: circle;
`

const ListItem = styled.li`
  padding-bottom: 12px;
`

const UnderlineText = styled.text`
  text-decoration: underline;
`

export default function PrivacyPolicy() {
  return (
    <PageWrapper>
      <Container>
        <Title>GlobalDEX Privacy Policy</Title>
        <Subtext>Last modified: July 6, 2023</Subtext>

        <p>
          <Paragraph>
            This Privacy Policy (the &quot;Policy&quot;) explains how GlobalDex (referred to herein as
            &quot;GlobalDEX&quot; or with pronouns such as &quot;we&quot;, &quot;our&quot;, or &quot;us&quot;) collects,
            uses, and shares data in connection with the GlobalDEX web app (app.GlobalDEX.com), www.GlobalDEX.com
            website and all of our other properties, products, and services (the &quot;Services&quot;). Your use of the
            Services is subject to this Policy as well as our{' '}
            <Link href="https://GlobalDEX.com/#/terms-of-service">Terms of Service</Link>.
          </Paragraph>
        </p>

        {/* Section: Summary */}
        <p>
          <Subheader>Summary</Subheader>
          <List>
            <ListItem>
              <Paragraph>
                Our GlobalDEX Protocol is a censorship-resistant set of smart contracts deployed across various Layer 1
                and Layer 2 chains. It is not governed by GlobalDEX.
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph>
                GlobalDEX <UnderlineText>does not</UnderlineText> collect and store personal data, such as first name,
                last name, street address, date of birth, email address, or IP address, in connection with your use of
                the Services.
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph>
                GlobalDEX collects non-identifiable data, such as public on-chain data, and limited off-chain data like
                device type, browser version, etc. This is to help us as we improve our Services; it is not used track
                users.
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph>
                GlobalDEX will take reasonable efforts to protect the privacy of our users and consumers.
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph>We like to empower our users to explore their own privacy techniques and tools.</Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph>Any material changes to privacy will be reflected in an updated privacy policy.</Paragraph>
            </ListItem>
          </List>
        </p>

        {/* Section: Data We Collect */}
        <p>
          <Subheader>Data We Collect</Subheader>
          <p>
            <Paragraph>
              Privacy is important. We strive for transparency regarding the little data we do collect. We do not
              maintain user accounts and do not collect and store personal data, such as your name or internet protocol
              (&quot;IP&quot;) address. When you interact with the Services, we collect only:
            </Paragraph>
          </p>
          <List>
            <ListItem>
              <Paragraph>
                <UnderlineText>Publicly available blockchain data.</UnderlineText> When you connect your non-custodial
                blockchain wallet to the Services, we collect and log your publicly available blockchain address to
                learn more about your use of the Services and to screen your wallet for any prior illicit activity. We
                screen your wallet using intelligence provided by analytics providers.{' '}
                <b>Blockchain addresses are publicly available data</b> that are not created or assigned by us or any
                central party, and by themselves are not personally identifying.
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph>
                <UnderlineText>Information from tracking technologies.</UnderlineText> We and our third-party services
                providers may access and collect information from localStorage, mobile deviceID, cookies, web beacons,
                and other similar technologies to provide and personalize the Services and features of the Services for
                you across sessions. For example, we may use this information to remember tokens you import, star, or
                bookmark. We may also use this information to learn about your preferences, your use of the Services,
                and our interactions with you. Information we collect from these technologies may include things such as
                browser type, referring/exit pages, operating system, device or browser language, and other device
                information. We group and analyze these user journeys collectively, in the aggregate, to improve our
                product user experience.
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph>
                <UnderlineText>Information you specifically provide to us.</UnderlineText> If you give us information,
                we may use that information for the purposes described when you provide it. We will not attempt to link
                any information you provide to your wallet address, IP address, or other personal data.{' '}
                <b>You do not need to provide us with any personal data to use the Services.</b>
              </Paragraph>
            </ListItem>
          </List>
        </p>

        {/* Section: How We Use Data */}
        <p>
          <Subheader>How We Use Data</Subheader>
          <p>
            <Paragraph>
              <UnderlineText>We do not collect or use any personal data.</UnderlineText> We use the data we do collect
              in accordance with your instructions, including any applicable terms in our{' '}
              <Link href="https://GlobalDEX.com/#/terms-of-service">Terms of Service</Link>, and as required by law. We
              may also use data for the following purposes:
            </Paragraph>
          </p>
          <List>
            <ListItem>
              <Paragraph>
                <UnderlineText>Providing our Services.</UnderlineText> To provide, maintain, customize, and improve our
                Services.
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph>
                <UnderlineText>Safety / security.</UnderlineText> To protect against, investigate, and stop fraudulent,
                unauthorized, or illegal activity. We also use it to address security risks, solve issues such as bugs,
                enforce our agreements, and protect our users and Company.
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph>
                <UnderlineText>Compliance.</UnderlineText> To ensure legal and regulatory compliance, or as lawfully
                requested by regulators, government entities, and law enforcement.
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph>
                <UnderlineText>Aggregated data.</UnderlineText> To compile aggregated data that helps us learn more
                about how users access and utilize the Services and where we can improve your experience.
              </Paragraph>
            </ListItem>
          </List>
        </p>

        {/* Section: How We Share Data */}
        <p>
          <Subheader>How We Share Data</Subheader>
          <p>
            <Paragraph>
              <UnderlineText>We do not collect or use any personal data.</UnderlineText> We may share or disclose the
              data we do collect as follows:
            </Paragraph>
          </p>
          <List>
            <ListItem>
              <Paragraph>
                <UnderlineText>With service providers.</UnderlineText> Our service providers and vendors assist us in
                providing, delivering, and improving the Services. We may share your wallet address with service
                providers like QuickNode and Cloudflare to provide technical infrastructure services, with blockchain
                analytics providers to detect, prevent, and mitigate financial crime and other illicit or harmful
                activities, and with our analytics provider to learn more about you interact with us and the Services.
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph>
                <UnderlineText>With regulatory and law enforcement to comply with our legal obligations.</UnderlineText>{' '}
                Litigation, regulatory proceedings, and compliance measures often require access to information obtained
                by companies like ours. We may also share data when we believe it is necessary to prevent harm to our
                users, our Company, or others, and to enforce our agreements and policies, including our{' '}
                <Link href="https://GlobalDEX.com/#/terms-of-service">Terms of Service</Link>.
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph>
                <UnderlineText>To promote safety and security.</UnderlineText> Data may be used to protect against,
                investigate, and stop fraudulent, unauthorized, or illegal/illicit activity. We may also use it to
                address security and other risks, solve potential security issues such as bugs, enforce our agreements,
                and protect our users, Company, and ecosystem.
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph>
                <UnderlineText>To consummate a business transaction.</UnderlineText> We may transfer or share data to
                another entity in the event of a merger, acquisition, bankruptcy, dissolution, reorganization, asset or
                stock sale, or other business transaction. Should this occur, your data will remain subject to these
                policies until the other entity responsible changes them in its discretion.
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph>
                <UnderlineText>With your consent.</UnderlineText> We may share your information any other time you
                provide us when you specifically agree to allow this.
              </Paragraph>
            </ListItem>
          </List>
          <p>
            <Paragraph>
              We do not share your information with any third parties for any marketing purposes whatsoever.
            </Paragraph>
          </p>
        </p>

        {/* Section: Third Party Cookies */}
        <p>
          <Subheader>Third Party Cookies</Subheader>
          <p>
            <Paragraph>
              We may use services provided by third parties that use tracking technology such as cookies, to collect
              information about your use of the Services and our interactions with you. You can opt out of having your
              online activity and device data collected through these third-party services, including by:
            </Paragraph>
          </p>
          <List>
            <ListItem>
              <Paragraph>
                Blocking cookies in your browser by following the instructions in your browser settings. For more
                information about cookies, including how to see the cookies on your device, manage them, and delete
                them, visit <Link href="https://www.allaboutcookies.org">www.allaboutcookies.org</Link>.
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph>
                Blocking or limiting the use of your advertising ID on your mobile device through the device settings.
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph>
                Using privacy plug-ins or browsers. Certain browsers and browser extensions can be configured to block
                third-party cookies and trackers.
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph>
                Using the platform opt-out provided by Google at{' '}
                <Link href="https://adssettings.google.com">https://adssettings.google.com</Link>. You can learn more
                about how Google uses your information by reviewing Google&apos;s privacy policy at{' '}
                <Link href="https://policies.google.com/privacy">https://policies.google.com/privacy</Link>.
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph>
                Using advertising industry opt-out tools applicable to a particular device or browser where you access
                the Services, available at <Link href="https://optout.aboutads.info">http://optout.aboutads.info</Link>{' '}
                and <Link href="https://optout.networkadvertising.org">http://optout.networkadvertising.org</Link>.
              </Paragraph>
            </ListItem>
          </List>
        </p>

        {/* Section: Third Party Sites */}
        <p>
          <Subheader>Third Party Sites</Subheader>
          <p>
            <Paragraph>
              Our Services may integrate technologies that are operated by other parties. For example, the Services may
              include hyperlinks to websites, platforms, and other services not operated or controlled by us. Please
              note that when you interact with these other parties, those parties may independently collect information
              about you and solicit information from you. You can learn more about how those parties collect and use
              your data by reviewing their privacy policies and other relevant privacy terms.
            </Paragraph>
          </p>
        </p>

        {/* Section: Security */}
        <p>
          <Subheader>Security</Subheader>
          <p>
            <Paragraph>
              We have built and will continue to maintain reasonable administrative, physical, and technical security
              safeguards to help protect the data we have from loss, theft, misuse, unauthorized access, disclosure,
              alteration, and destruction. Transmission via the internet is not completely secure and we cannot
              guarantee the security of your data. You are responsible for all of your activity on the Services,
              including the security of your blockchain network addresses, cryptocurrency wallets, and their
              cryptographic keys.
            </Paragraph>
          </p>
        </p>

        {/* Section: Age Requirements */}
        <p>
          <Subheader>Age Requirements</Subheader>
          <p>
            <Paragraph>
              The Services are intended for a general audience and are not directed at children. We do not knowingly
              receive personal data or information from children.
            </Paragraph>
          </p>
        </p>

        {/* Section: Additional Notice to California Residents ("CCPA Notice") */}
        <p>
          <Subheader>Additional Notice to California Residents</Subheader>
          <Subheader>(&quot;CCPA Notice&quot;)</Subheader>
          <p>
            <Paragraph>
              We do not collect or use any personal data. We do not market or offer services to residents of the United
              States. However, should The California Consumer Privacy Act of 2018 (&quot;CCPA&quot;) apply to any data
              we do collect, the CCPA requires certain businesses to provide a CCPA Notice to California residents to
              explain how we collect, use, and share their personal information, and the rights and choices we offer
              California residents regarding our handling of their information.
            </Paragraph>
          </p>
          <List>
            <ListItem>
              <Paragraph>
                <UnderlineText>Privacy Practices.</UnderlineText> We do not &quot;sell&quot; personal information as
                defined by the CCPA. Please review the &quot;<b>How We Share Data</b>&quot; section above for further
                details about the categories of parties with whom we share information.
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph>
                <UnderlineText>Privacy Rights.</UnderlineText> The CCPA gives individuals the right to request
                information about how we have collected, used, and shared your personal information. It also gives you
                the right to request a copy of any information we may maintain about you. You may also ask us to delete
                any personal information that we may have received about you. Please note that the CCPA limits these
                rights, for example, by prohibiting us from providing certain sensitive information in response to
                access requests and limiting the circumstances under which we must comply with a deletion request. We
                will respond to requests for information, access, and deletion only to the extent we are able to
                associate, with a reasonable effort, the information we maintain with the identifying details you
                provide in your request. If we deny the request, we will communicate the decision to you. You are
                entitled to exercise the rights described above free from discrimination.
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph>
                <UnderlineText>Identity Verification.</UnderlineText> The CCPA requires us to collect and verify the
                identity of any individual submitting a request to access or delete personal information before
                providing a substantive response.
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph>
                <UnderlineText>Authorized Agents.</UnderlineText> California residents can designate an &quot;authorized
                agent&quot; to submit requests on their behalf. We will require the authorized agent to have a written
                authorization confirming their authority.
              </Paragraph>
            </ListItem>
          </List>
        </p>

        {/* Section: Disclosures for European Union Data Subjects */}
        <p>
          <Subheader>Disclosures for European Union Data Subjects</Subheader>
          <Paragraph>
            <b>
              <p>
                We process personal data for the purposes described in the section titled &quot;How We Use Personal
                Data&quot; above. Our bases for processing your data include: (i) you have given consent to the process
                to us or our service provides for one or more specific purposes; (ii) processing is necessary for the
                performance of a contract with you; (iii) processing is necessary for compliance with a legal
                obligation; and/or (iv) processing is necessary for the purposes of the legitimate interested pursued by
                us or a third party, and your interests and fundamental rights and freedoms do not override those
                interests.
              </p>
              <p>
                Your rights under the General Data Protection Regulations (&quot;GDPR&quot;) include the right to (i)
                request access and obtain a copy of your personal data, (ii) request rectification or erasure of your
                personal data, (iii) object to or restrict the processing of your personal data; and (iv) request
                portability of your personal data. Additionally, you may withdraw your consent to our collection at any
                time.{' '}
                <UnderlineText>
                  Nevertheless, we cannot edit or delete information that is stored on a particular blockchain.
                  Information such as your transaction data, blockchain wallet address, and assets held by your address
                  that may be related to the data we collect is beyond our control.
                </UnderlineText>
              </p>
              <p>
                To exercise any of your rights under the GDPR, you may do so using the &quot;contact us&quot; link we
                provide in the app. You are empowered to deal with your data in the manner you see fit. Please note that
                we may retain information as necessary to fulfill the purpose for which it was collected and may
                continue to do so even after a data subject request in accordance with our legitimate interests,
                including to comply with our legal obligations, resolves disputes, prevent fraud, and enforce our
                agreements.
              </p>
            </b>
          </Paragraph>
        </p>

        {/* Section: Changes to this Policy */}
        <p>
          <Subheader>Changes to this Policy</Subheader>
          <p>
            <Paragraph>
              We may make material changes to this Policy in the future to better comply with evolving privacy
              regulations. The date above will change to reflect the policy change date. Your continued use of the
              Services reflects your periodic review of this Policy and other Company terms and indicates your consent.
            </Paragraph>
          </p>
        </p>
      </Container>
    </PageWrapper>
  )
}
