import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import { Center } from '../../nft/components/Flex'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  max-width: 800px;
`

const PageWrapper = styled(Container)`
  flex: 1;
  justify-content: center;
  padding: 50px;
  text-align: justify;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    justify-content: space-between;
    padding-top: 64px;
  }
`

const Title = styled(ThemedText.HeadlineLarge).attrs((props) => ({
  style: {
    fontWeight: 500,
  },
}))``

const Subheader = styled(ThemedText.HeadlineSmall).attrs((props) => ({
  style: {
    fontWeight: 800,
    lineHeight: '2rem',
  },
}))``

const Descriptor = styled(ThemedText.HeadlineSmall).attrs((props) => ({
  style: {
    fontWeight: 800,
  },
}))``

const Subtext = styled(ThemedText.SubHeader).attrs((props) => ({
  style: {
    fontWeight: 500,
    fontStyle: 'italic',
    lineHeight: '3rem',
  },
}))``

const Paragraph = styled(ThemedText.BodyPrimary).attrs((props) => ({
  style: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '1.5rem',
  },
}))``

const Link = styled.a`
  color: ${({ theme }) => theme.textPrimary};
`

const List = styled.ul`
  list-style-type: circle;
`

const ListItem = styled.li`
  padding-bottom: 12px;
`

const Tab = styled.span`
  margin-left: 25px;
`

const UnderlineText = styled.text`
  text-decoration: underline;
`

export default function TermsOfService() {
  return (
    <PageWrapper>
      <Container>
        <Center>
          <Title>GlobalDEX Terms of Service</Title>
        </Center>
        <Subtext>Last modified: July 6, 2023</Subtext>
        <Paragraph>
          <p>
            These Terms of Service (the &quot;Agreement&quot;) explain the terms and conditions by which you may access
            and use the Product provided by GlobalDEX, a Bahrain Limited Liability Company currently under formation
            (referred to herein as &quot;GlobalDEX&quot; or with pronouns such as &quot;we&quot;, &quot;our&quot;, or
            &quot;us&quot;). The Product shall include, but shall not necessarily be limited to, www.GlobalDEX.com, our
            website-hosted user interface (the &quot;Interface&quot; or &quot;App&quot;). You must read this Agreement
            carefully as it governs your use of the Product. By accessing or using the Product, you signify that you
            have read, understand, and agree to be bound by this Agreement in its entirety. If you do not agree, you are
            not authorized to access or use the Product and should not use our Product.
          </p>
          <p>
            To access or use the Product, you must be able to form a legally binding contract with us. Accordingly, you
            represent that you are at least the age of majority in your jurisdiction and have the full right, power, and
            authority to enter into and comply with the terms and conditions of this Agreement on behalf of yourself and
            any company or legal entity for which you may access or use the Interface. If you are entering into this
            Agreement on behalf of an entity, you represent to us that you have the legal authority to bind such entity.
          </p>
          <p>
            You further represent that you are not (a) the subject of economic or trade sanctions administered or
            enforced by any governmental authority or otherwise designated on any list of prohibited or restricted
            parties (including but not limited to the list maintained by the Office of Foreign Assets Control of the
            U.S. Department of the Treasury) or (b) a citizen, resident, or organized in a jurisdiction or territory
            that is the subject of comprehensive country-wide, territory-wide, or regional economic sanctions by the
            United States. Finally, you represent that your access and use of the Product will fully comply with all
            applicable laws and regulations, and that you will not access or use the Product to conduct, promote, or
            otherwise facilitate any illegal activity.
          </p>
          <p>
            <b>
              NOTICE: This Agreement contains important information, including a binding arbitration provision and a
              class action waiver, both of which impact your rights as to how disputes are resolved. Our Product are
              only available to you — and you should only access the Product — if you agree completely with these terms.
            </b>
          </p>

          <Subheader>1. Our Product</Subheader>
          <p>
            <b>1.1 The Interface</b>
          </p>
          <p>
            The Interface provides a web or mobile-based means of access to (a) a decentralized protocol on various
            public blockchains, including but not limited to Ethereum, that allows users to trade certain compatible
            digital assets (the &quot;GlobalDEX protocol&quot; or the &quot;Protocol&quot;).
          </p>
          <p>
            The Interface is distinct from the Protocol and is one, but not the exclusive, means of accessing the
            Protocol. The Protocol comprises open-source or source-available self-executing smart contracts that are
            deployed on various public blockchains, such as Ethereum. GlobalDEX does not control or operate any version
            of the Protocol on any blockchain network. By using the Interface, you understand that you are not buying or
            selling digital assets from us and that we do not operate any liquidity pools on the Protocol or control
            trade execution on the Protocol. When traders pay fees for trades, those fees accrue to liquidity providers
            for the Protocol. As a general matter, GlobalDEX is not a liquidity provider into Protocol liquidity pools
            and liquidity providers are independent third parties. The Protocol was initially deployed on the Arbitrum
            One blockchain.
          </p>
          <p>
            To access the Interface, you must obtain (or already have obtained) and use a non-custodial third-party
            wallet software, which allows you to interact with public blockchains. Your relationship with that
            non-custodial wallet provider is governed by the terms of service applicable to such third-party wallet. We
            do not have custody or control over the contents of your wallet and have no ability to retrieve or transfer
            its contents. By connecting your wallet to our Interface, you agree to be bound by this Agreement and all of
            the terms incorporated herein by reference.
          </p>

          <p>
            <b>1.2 Other Products</b>
          </p>
          <p>
            We may from time to time in the future offer additional products, and such additional products shall be
            considered a Product as used herein, regardless of whether such product is specifically defined in this
            Agreement.
          </p>

          <p>
            <b>1.3 Third Party Services and Content</b>
          </p>
          <p>
            When you use the Product, you may also be using the products, services or content of one or more third
            parties. Your use of such third-party products, services or content may be subject to separate policies,
            terms of use and fees of these third parties, and you agree to abide by and be responsible for such
            policies, terms of use and fees, as applicable.
          </p>

          <Subheader>2. Modifications of this Agreement or our Product</Subheader>
          <p>
            <b>2.1 Modifications of this Agreement</b>
          </p>
          <p>
            We reserve the right, in our sole discretion, to modify this Agreement from time to time. If we make any
            material modifications, we will update the date at the top of the Agreement and maintain a current version
            of the Agreement at{' '}
            <Link href="https://GlobalDEX.com/#/terms-of-service">https://GlobalDEX.com/#/terms-of-service</Link>. All
            modifications will be effective when they are posted, and your continued accessing or use of the Product
            will serve as confirmation of your acceptance of those modifications. If you do not agree with any
            modifications to this Agreement, you must immediately stop accessing and using all of our Product.
          </p>

          <p>
            <b>2.2 Modifications of our Product</b>
          </p>
          <p>
            We reserve the following rights, which do not constitute obligations of ours: (a) with or without notice to
            you, to modify, substitute, eliminate or add to the Product; (b) to review, modify, filter, disable, delete
            and remove any and all content and information from the Product.
          </p>

          <Subheader>3. Intellectual Property Rights</Subheader>
          <p>
            <b>3.1 IP Rights Generally</b>
          </p>
          <p>
            We own all intellectual property and other rights in the Product and its respective contents, including, but
            not limited to, software, text, images, trademarks, service marks, copyrights, patents, designs, and its
            &quot;look and feel.&quot; This intellectual property is available under the terms of this Agreement.
            Subject to the terms of this Agreement, we grant you a limited, revocable, non-exclusive, non-sublicensable,
            non-transferable license to access and use our Product solely in accordance with this Agreement. You agree
            that you will not use, modify, distribute, tamper with, reverse engineer, disassemble or decompile the
            Product for any purpose other than as expressly permitted pursuant to this Agreement. Except as set forth in
            this Agreement, we grant you no rights to the Product, including any intellectual property rights.
          </p>
          <p>
            <b>You understand and acknowledge that the Protocol is not a Product and we do not control the Protocol.</b>
          </p>
          <p>
            By using the Product, you grant us a worldwide, non-exclusive, sublicensable, royalty-free license to use,
            copy, modify, and display any content, including but not limited to text, materials, images, files,
            communications, comments, feedback, suggestions, ideas, concepts, questions, data, or otherwise, that you
            post on or through the Product for our current and future business purposes, including to provide, promote,
            and improve the services. This includes any digital file, art, or other material that is displayed. You
            grant to us a non-exclusive, transferable, worldwide, perpetual, irrevocable, fully-paid, royalty-free
            license, with the right to sublicense, under any and all intellectual property rights that you own or
            control to use, copy, modify, create derivative works based upon any suggestions or feedback for any
            purpose.
          </p>
          <p>
            You represent and warrant that you have, or have obtained, all rights, licenses, consents, permissions,
            power and/or authority necessary to grant the rights granted herein for any material that you list, post,
            promote, or display on or through the Product. You represent and warrant that such content does not contain
            material subject to copyright, trademark, publicity rights, or other intellectual property rights, unless
            you have necessary permission or are otherwise legally entitled to post the material and to grant us the
            license described above, and that the content does not violate any laws.
          </p>

          <p>
            <b>3.2 Third-Party Resources and Promotions</b>
          </p>
          <p>
            Our Product may contain references or links to third-party resources, including, but not limited to:
            information, materials, products, or services that we do not own or control. In addition, third parties may
            offer promotions related to your access and use of our Product. We do not approve, monitor, endorse, warrant
            or assume any responsibility for any such resources or promotions. If you access any such resources or
            participate in any such promotions, you do so at your own risk, and you understand that this Agreement does
            not apply to your dealings or relationships with any third parties. You expressly relieve us of any and all
            liability arising from your use of any such resources or participation in any such promotions.
          </p>

          <p>
            <b>3.3 Additional Rights</b>
          </p>
          <p>
            We reserve the right to cooperate with any law enforcement, court or government investigation or order or
            third party requesting or directing that we disclose information or content or information that you provide.
          </p>

          <Subheader>4. Your Responsibilities</Subheader>
          <p>
            <b>4.1 Prohibited Activity</b>
          </p>
          <p>
            You agree not to engage in, or attempt to engage in, any of the following categories of prohibited activity
            in relation to your access and use of the Interface:
          </p>
          <List>
            <ListItem>
              <UnderlineText>Market Manipulation.</UnderlineText> Activity that violates any applicable law, rule, or
              regulation concerning the integrity of trading markets, including, but not limited to, the manipulative
              tactics such as &quot;pumping and dumping.&quot;
            </ListItem>
            <ListItem>
              <UnderlineText>Securities and Derivatives Violations.</UnderlineText> Activity that violates any
              applicable law, rule, or regulation concerning the trading of securities or derivatives, including, but
              not limited to, the unregistered offering of securities and the offering of leveraged and margined
              commodity products to retail customers in the United States or any jurisdiction where this would be
              prohibited.
            </ListItem>
            <ListItem>
              <UnderlineText>Fraud and Misrepresentation.</UnderlineText> Activity that seeks to defraud us or any other
              person or entity, including, but not limited to, providing any false, inaccurate, or misleading
              information in order to unlawfully obtain the property of another.
            </ListItem>
            <ListItem>
              <UnderlineText>Sale of Stolen Property.</UnderlineText> Buying, selling, or transferring of stolen items,
              fraudulently obtained items, items taken without authorization, and/or any other illegally obtained items.
            </ListItem>
            <ListItem>
              <UnderlineText>Cyber-attack.</UnderlineText> Activity that seeks to interfere with or compromise the
              integrity, security, or proper functioning of any computer, server, network, personal device, or other
              information technology system, including, but not limited to, the deployment of viruses and denial of
              service attacks.
            </ListItem>
            <ListItem>
              <UnderlineText>Intellectual Property Infringement.</UnderlineText> Activity that infringes on or violates
              any copyright, trademark, service mark, patent, right of publicity, right of privacy, or other proprietary
              or intellectual property rights under the law.
            </ListItem>
            <ListItem>
              <UnderlineText>Data Mining or Scraping.</UnderlineText> Activity that involves data mining, robots,
              scraping, or similar data gathering or extraction methods of content or information from the Product.
            </ListItem>
            <ListItem>
              <UnderlineText>Objectionable Content.</UnderlineText> Activity that involves soliciting information from
              anyone under the age of 18 or that is otherwise harmful, threatening, abusive, harassing, tortious,
              excessively violent, defamatory, vulgar, obscene, pornographic, libelous, invasive of another&apos;s
              privacy, hateful, discriminatory, or otherwise objectionable.
            </ListItem>
            <ListItem>
              <UnderlineText>Any Other Unlawful Conduct.</UnderlineText> Activity that violates any applicable law,
              rule, or regulation any relevant jurisdiction.
            </ListItem>
          </List>

          <p>
            <b>4.2 Trading</b>
          </p>
          <p>You agree and understand that:</p>
          <p>
            <UnderlineText>
              <b>
                <Tab />
                (a) all trades you submit through the Product are considered unsolicited, which means that they are
                solely initiated by you;
              </b>
            </UnderlineText>
          </p>
          <p>
            <UnderlineText>
              <b>
                <Tab />
                (b) you have not received any investment advice from us in connection with any trades; and
              </b>
            </UnderlineText>
          </p>
          <p>
            <UnderlineText>
              <b>
                <Tab />
                (c) we do not conduct a suitability review of any trades you submit.
              </b>
            </UnderlineText>
          </p>

          <p>
            <b>4.3 Non-Custodial and No Fiduciary Duties</b>
          </p>
          <p>
            The Product is a purely non-custodial application. This means that we do not ever have custody, possession,
            or control of your digital assets at any time. It further means you are solely responsible for the custody
            of the cryptographic private keys to your digital asset wallet(s) and you should never share your wallet
            credentials or seed phrase with anyone. We accept no responsibility for, or liability to you, in connection
            with your use of a wallet and make no representations or warranties regarding how the Product will operate
            with any specific wallet. Likewise, you are solely responsible for any associated wallet, and we are not
            liable for any acts or omissions by you in connection with or as a result of your wallet being compromised.
          </p>
          <p>
            This Agreement is not intended to, and does not, create or impose any fiduciary duties on us. To the fullest
            extent permitted by law, you acknowledge and agree that we owe no fiduciary duties or liabilities to you or
            any other party, and that to the extent any such duties or liabilities may exist at law or in equity, those
            duties and liabilities are hereby irrevocably disclaimed, waived, and eliminated. You further agree that the
            only duties and obligations that we owe you are those set out expressly in this Agreement.
          </p>

          <p>
            <b>4.4 Compliance and Tax Obligations</b>
          </p>
          <p>
            Our Product may not be available or appropriate for use in your jurisdiction. By accessing or using the
            Product, you agree that you are solely and entirely responsible for compliance with all laws and regulations
            that may apply to you.
          </p>
          <p>
            Specifically, your use of our Product or the Protocol may result in various tax consequences, such as income
            or capital gains tax, value-added tax, goods and services tax, or sales tax in certain jurisdictions.
          </p>
          <p>
            It is your responsibility to determine whether taxes apply to any transactions you initiate or receive and,
            if so, to report and/or remit the correct tax to the appropriate tax authority.
          </p>

          <p>
            <b>4.5 Gas Fees</b>
          </p>
          <p>
            Blockchain transactions require the payment of transaction fees to the appropriate network (&quot;Gas
            Fees&quot;). Except as otherwise expressly set forth in the terms of another offer by GlobalDEX, you will be{' '}
            <UnderlineText>
              <b>solely responsible</b>
            </UnderlineText>{' '}
            to pay the Gas Fees for any transaction that you initiate via the Product.
          </p>

          <p>
            <b>4.6 Release of Claims</b>
          </p>
          <p>
            You expressly agree that you assume all risks in connection with your access and use of the Product. You
            further expressly waive and release us from any and all liability, claims, causes of action, or damages
            arising from or in any way relating to your use of the Product.
          </p>
          <p>
            We do not market or offer services to residents of the United States. However, if it is found that you are a
            California resident, you waive the benefits and protections of California Civil Code § 1542, which provides:
            &quot;[a] general release does not extend to claims that the creditor or releasing party does not know or
            suspect to exist in his or her favor at the time of executing the release and that, if known by him or her,
            would have materially affected his or her settlement with the debtor or released party.&quot;
          </p>

          <Subheader>5. DISCLAIMERS</Subheader>
          <p>
            <b>5.1 ASSUMPTION OF RISK -- GENERALLY</b>
          </p>
          <p>
            BY ACCESSING AND USING THE PRODUCT, YOU REPRESENT THAT YOU ARE FINANCIALLY AND TECHNICALLY SOPHISTICATED
            ENOUGH TO UNDERSTAND THE INHERENT RISKS ASSOCIATED WITH USING CRYPTOGRAPHIC AND BLOCKCHAIN-BASED SYSTEMS,
            AND THAT YOU HAVE A WORKING KNOWLEDGE OF THE USAGE AND INTRICACIES OF DIGITAL ASSETS SUCH AS ETHER (ETH),
            SO-CALLED STABLECOINS, AND OTHER DIGITAL TOKENS SUCH AS THOSE FOLLOWING THE ETHEREUM TOKEN STANDARD
            (ERC-20).
          </p>
          <p>
            IN PARTICULAR, YOU UNDERSTAND THAT THE MARKETS FOR DIGITAL ASSETS – EVEN ASSETS BACKED BY LISTED SECURITIES
            – ARE NASCENT AND HIGHLY VOLATILE DUE TO RISK FACTORS INCLUDING, BUT NOT LIMITED TO, ADOPTION, SPECULATION,
            TECHNOLOGY, SECURITY, AND REGULATION. YOU UNDERSTAND THAT ANYONE CAN CREATE A TOKEN, INCLUDING FAKE VERSIONS
            OF EXISTING TOKENS AND TOKENS THAT FALSELY CLAIM TO REPRESENT ASSETS THAT DO NOT EXIST, AND YOU ACKNOWLEDGE
            AND ACCEPT THE RISK THAT YOU MAY MISTAKENLY TRADE THOSE OR OTHER TOKENS. SO-CALLED STABLECOINS MAY NOT BE AS
            STABLE AS THEY PURPORT TO BE, MAY NOT BE FULLY OR ADEQUATELY COLLATERALIZED, AND MAY BE SUBJECT TO PANICS
            AND RUNS.
          </p>
          <p>
            FURTHER, YOU UNDERSTAND THAT SMART CONTRACT TRANSACTIONS AUTOMATICALLY EXECUTE AND SETTLE, AND THAT
            BLOCKCHAIN-BASED TRANSACTIONS ARE IRREVERSIBLE WHEN CONFIRMED. YOU ACKNOWLEDGE AND ACCEPT THAT THE COST AND
            SPEED OF TRANSACTING WITH CRYPTOGRAPHIC AND BLOCKCHAIN-BASED SYSTEMS SUCH AS ETHEREUM ARE VARIABLE AND MAY
            INCREASE DRAMATICALLY AT ANY TIME. YOU FURTHER ACKNOWLEDGE AND ACCEPT THE RISK OF SELECTING TO TRADE IN
            EXPERT MODES, WHICH CAN EXPOSE YOU TO POTENTIALLY SIGNIFICANT PRICE SLIPPAGE AND HIGHER COSTS.
          </p>
          <p>
            IF YOU ACT AS A LIQUIDITY PROVIDER TO THE PROTOCOL THROUGH THE INTERFACE, YOU UNDERSTAND THAT YOUR DIGITAL
            ASSETS MAY LOSE SOME OR ALL OF THEIR VALUE WHILE THEY ARE SUPPLIED TO THE PROTOCOL THROUGH THE INTERFACE DUE
            TO THE FLUCTUATION OF PRICES OF TOKENS IN A TRADING PAIR OR LIQUIDITY POOL.
          </p>
          <p>
            FINALLY, YOU UNDERSTAND THAT WE DO NOT CREATE, OWN, OR OPERATE CROSS-CHAIN BRIDGES AND WE DO NOT MAKE ANY
            REPRESENTATION OR WARRANTY ABOUT THE SAFETY OR SOUNDNESS OF ANY CROSS-CHAIN BRIDGE.
          </p>
          <p>
            IN SUMMARY, YOU ACKNOWLEDGE THAT WE ARE NOT RESPONSIBLE FOR ANY OF THESE VARIABLES OR RISKS, DO NOT OWN OR
            CONTROL THE PROTOCOL, AND CANNOT BE HELD LIABLE FOR ANY RESULTING LOSSES THAT YOU EXPERIENCE WHILE ACCESSING
            OR USING THE PRODUCT. ACCORDINGLY, YOU UNDERSTAND AND AGREE TO ASSUME FULL RESPONSIBILITY FOR ALL OF THE
            RISKS OF ACCESSING AND USING THE INTERFACE TO INTERACT WITH THE PROTOCOL.
          </p>

          <p>
            <b>5.2 NO WARRANTIES</b>
          </p>
          <p>
            THE PRODUCT IS PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS. TO THE FULLEST EXTENT
            PERMITTED BY LAW, WE DISCLAIM ANY REPRESENTATIONS AND WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR
            STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
            PURPOSE. YOU ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE PRODUCT IS AT YOUR OWN RISK. WE DO NOT REPRESENT OR
            WARRANT THAT ACCESS TO THE PRODUCT WILL BE CONTINUOUS, UNINTERRUPTED, TIMELY, OR SECURE; THAT THE
            INFORMATION CONTAINED IN THE PRODUCT WILL BE ACCURATE, RELIABLE, COMPLETE, OR CURRENT; OR THAT THE PRODUCT
            WILL BE FREE FROM ERRORS, DEFECTS, VIRUSES, OR OTHER HARMFUL ELEMENTS. NO ADVICE, INFORMATION, OR STATEMENT
            THAT WE MAKE SHOULD BE TREATED AS CREATING ANY WARRANTY CONCERNING THE PRODUCT. WE DO NOT ENDORSE,
            GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY ADVERTISEMENTS, OFFERS, OR STATEMENTS MADE BY THIRD PARTIES
            CONCERNING THE PRODUCT.
          </p>
          <p>
            SIMILARLY, THE PROTOCOL IS PROVIDED &quot;AS IS&quot;, AT YOUR OWN RISK, AND WITHOUT WARRANTIES OF ANY KIND.
            ALTHOUGH WE CONTRIBUTED TO THE INITIAL CODE FOR THE PROTOCOL, WE DO NOT PROVIDE, OWN, OR CONTROL THE
            PROTOCOL, WHICH IS RUN AUTONOMOUSLY WITHOUT ANY HEADCOUNT BY SMART CONTRACTS DEPLOYED ON VARIOUS
            BLOCKCHAINS. NO DEVELOPER OR ENTITY INVOLVED IN CREATING THE PROTOCOL WILL BE LIABLE FOR ANY CLAIMS OR
            DAMAGES WHATSOEVER ASSOCIATED WITH YOUR USE, INABILITY TO USE, OR YOUR INTERACTION WITH OTHER USERS OF, THE
            PROTOCOL, INCLUDING ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES,
            OR LOSS OF PROFITS, CRYPTOCURRENCIES, TOKENS, OR ANYTHING ELSE OF VALUE. WE DO NOT ENDORSE, GUARANTEE, OR
            ASSUME RESPONSIBILITY FOR ANY ADVERTISEMENTS, OFFERS, OR STATEMENTS MADE BY THIRD PARTIES CONCERNING THE
            PRODUCT.
          </p>
          <p>
            ANY PAYMENTS OR FINANCIAL TRANSACTIONS THAT YOU ENGAGE IN WILL BE PROCESSED VIA AUTOMATED SMART CONTRACTS.
            ONCE EXECUTED, WE HAVE NO CONTROL OVER THESE PAYMENTS OR TRANSACTIONS, NOR DO WE HAVE THE ABILITY TO REVERSE
            ANY PAYMENTS OR TRANSACTIONS.
          </p>

          <p>
            <b>5.3 NO INVESTMENT ADVICE</b>
          </p>
          <p>
            WE MAY PROVIDE INFORMATION ABOUT TOKENS IN OUR PRODUCT SOURCED FROM THIRD-PARTY DATA PARTNERS. WE MAY ALSO
            PROVIDE WARNING LABELS FOR CERTAIN TOKENS. THE PROVISION OF INFORMATIONAL MATERIALS DOES NOT MAKE TRADES IN
            THOSE TOKENS SOLICITED; WE ARE NOT ATTEMPTING TO INDUCE YOU TO MAKE ANY PURCHASE AS A RESULT OF INFORMATION
            PROVIDED. ALL SUCH INFORMATION PROVIDED BY THE PRODUCT IS FOR INFORMATIONAL PURPOSES ONLY AND SHOULD NOT BE
            CONSTRUED AS INVESTMENT ADVICE OR A RECOMMENDATION THAT A PARTICULAR TOKEN IS A SAFE OR SOUND INVESTMENT.
            YOU SHOULD NOT TAKE, OR REFRAIN FROM TAKING, ANY ACTION BASED ON ANY INFORMATION CONTAINED IN THE PRODUCT.
            BY PROVIDING TOKEN INFORMATION FOR YOUR CONVENIENCE, WE DO NOT MAKE ANY INVESTMENT RECOMMENDATIONS TO YOU OR
            OPINE ON THE MERITS OF ANY TRANSACTION OR OPPORTUNITY. YOU ALONE ARE RESPONSIBLE FOR DETERMINING WHETHER ANY
            INVESTMENT, INVESTMENT STRATEGY OR RELATED TRANSACTION IS APPROPRIATE FOR YOU BASED ON YOUR PERSONAL
            INVESTMENT OBJECTIVES, FINANCIAL CIRCUMSTANCES, AND RISK TOLERANCE.
          </p>

          <Subheader>6. Indemnification</Subheader>
          <p>
            To the greatest extent allowed under applicable law, you agree to hold harmless, release, defend, and
            indemnify us and our officers, directors, employees, contractors, agents, affiliates, and subsidiaries from
            and against all claims, damages, obligations, losses, liabilities, costs, and expenses arising from: (a)
            your access and use of the Product; (b) your violation of any term or condition of this Agreement, the right
            of any third party, or any other applicable law, rule, or regulation; (c) any other party&apos;s access and
            use of the Product with your assistance or using any device or account that you own or control; and (d) any
            dispute between you and (i) any other user of the Product or (ii) any of your own customers or users.
          </p>

          <Subheader>7. Limitation of Liability</Subheader>
          <p>
            UNDER NO CIRCUMSTANCES SHALL WE OR ANY OF OUR OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS, AGENTS,
            AFFILIATES, OR SUBSIDIARIES BE LIABLE TO YOU FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
            OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR
            OTHER INTANGIBLE PROPERTY, ARISING OUT OF OR RELATING TO ANY ACCESS OR USE OF OR INABILITY TO ACCESS OR USE
            THE PRODUCT, NOR WILL WE BE RESPONSIBLE FOR ANY DAMAGE, LOSS, OR INJURY RESULTING FROM HACKING, TAMPERING,
            OR OTHER UNAUTHORIZED ACCESS OR USE OF THE PRODUCT OR THE INFORMATION CONTAINED WITHIN IT, WHETHER SUCH
            DAMAGES ARE BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, ARISING OUT OF OR IN
            CONNECTION WITH AUTHORIZED OR UNAUTHORIZED USE OF THE PRODUCT, EVEN IF AN AUTHORIZED REPRESENTATIVE OF
            GLOBALDEX HAS BEEN ADVISED OF OR KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. WE ASSUME NO
            LIABILITY OR RESPONSIBILITY FOR ANY: (A) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT; (B) PERSONAL INJURY
            OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM ANY ACCESS OR USE OF THE INTERFACE; (C)
            UNAUTHORIZED ACCESS OR USE OF ANY SECURE SERVER OR DATABASE IN OUR CONTROL, OR THE USE OF ANY INFORMATION OR
            DATA STORED THEREIN; (D) INTERRUPTION OR CESSATION OF FUNCTION RELATED TO THE PRODUCT; (E) BUGS, VIRUSES,
            TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH THE INTERFACE; (F) ERRORS OR OMISSIONS IN,
            OR LOSS OR DAMAGE INCURRED AS A RESULT OF THE USE OF, ANY CONTENT MADE AVAILABLE THROUGH THE PRODUCT; AND
            (G) THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY.
          </p>
          <p>
            WE HAVE NO LIABILITY TO YOU OR TO ANY THIRD PARTY FOR ANY CLAIMS OR DAMAGES THAT MAY ARISE AS A RESULT OF
            ANY PAYMENTS OR TRANSACTIONS THAT YOU ENGAGE IN VIA THE PRODUCT, OR ANY OTHER PAYMENT OR TRANSACTIONS THAT
            YOU CONDUCT VIA THE PRODUCT. EXCEPT AS EXPRESSLY PROVIDED FOR HEREIN, WE DO NOT PROVIDE REFUNDS FOR ANY
            PURCHASES THAT YOU MIGHT MAKE ON OR THROUGH THE PRODUCT.
          </p>
          <p>
            WE MAKE NO WARRANTIES OR REPRESENTATIONS, EXPRESS OR IMPLIED, ABOUT LINKED THIRD PARTY SERVICES, THE THIRD
            PARTIES THEY ARE OWNED AND OPERATED BY, THE INFORMATION CONTAINED ON THEM, ASSETS AVAILABLE THROUGH THEM, OR
            THE SUITABILITY, PRIVACY, OR SECURITY OF THEIR PRODUCT OR SERVICES. YOU ACKNOWLEDGE SOLE RESPONSIBILITY FOR
            AND ASSUME ALL RISK ARISING FROM YOUR USE OF THIRD-PARTY SERVICES, THIRD-PARTY WEBSITES, APPLICATIONS, OR
            RESOURCES. WE SHALL NOT BE LIABLE UNDER ANY CIRCUMSTANCES FOR DAMAGES ARISING OUT OF OR IN ANY WAY RELATED
            TO SOFTWARE, PRODUCT, SERVICES, AND/OR INFORMATION OFFERED OR PROVIDED BY THIRD PARTIES AND ACCESSED THROUGH
            THE PRODUCT.
          </p>
          <p>
            SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF INCIDENTAL OR
            CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU. IN NO EVENT SHALL OUR TOTAL LIABILITY TO YOU
            FOR ALL DAMAGES (OTHER THAN AS MAY BE REQUIRED BY APPLICABLE LAW IN CASES INVOLVING PERSONAL INJURY) EXCEED
            THE AMOUNT OF ONE HUNDRED U.S. DOLLARS ($100.00 USD) OR ITS EQUIVALENT IN THE LOCAL CURRENCY OF THE
            APPLICABLE JURISDICTION.
          </p>
          <p>THE FOREGOING DISCLAIMER WILL NOT APPLY TO THE EXTENT PROHIBITED BY LAW.</p>

          <Subheader>8. Governing Law, Dispute Resolution and Class Action Waivers</Subheader>
          <p>
            <b>8.1 Governing Law</b>
          </p>
          <p>
            You agree that the laws of the Kingdom of Bahrain, without regard to principles of conflict of laws, govern
            this Agreement and any Dispute between you and us. You further agree that the Product shall be deemed to be
            based solely in the Kingdom of Bahrain, and that although a Product may be available in other jurisdictions,
            its availability does not give rise to general or specific personal jurisdiction in any forum outside the
            Kingdom of Bahrain. You agree that the Civil Judiciary of Bahrain in Manama, the Kingdom of Bahrain is the
            proper forum for any appeals of an arbitration award or for court proceedings in the event that this
            Agreement&apos;s binding arbitration clause is found to be unenforceable.
          </p>

          <p>
            <b>8.2 Dispute Resolution</b>
          </p>
          <p>
            We will use our best efforts to resolve any potential disputes through informal, good faith negotiations. If
            a potential dispute arises, you must alert us as by using the &quot;contact us&quot; link we provide in the
            app soon as possible so that we can attempt to resolve it without resorting to formal dispute resolution. If
            we aren&apos;t able to reach an informal resolution within sixty days of your email, then you and we both
            agree to resolve the potential dispute according to the process set forth below.
          </p>
          <p>
            Any dispute arising out of or in connection with this Agreement, including any question regarding its
            existence, validity or termination, shall be finally settled by arbitration in accordance with the Rules of
            Arbitration of the Bahrain Chamber for Dispute Resolution. The arbitral tribunal shall consist of one
            arbitrator. The place of arbitration shall be Manama, Kingdom of Bahrain. The language of the arbitration
            shall be English.
          </p>

          <p>
            <b>8.3 Class Action and Jury Trial Waiver</b>
          </p>
          <p>
            You must bring any and all Disputes against us in your individual capacity and not as a plaintiff in or
            member of any purported class action, collective action, private attorney general action, or other
            representative proceeding. This provision applies to class arbitration. You and we both agree to waive any
            right to demand a trial by jury.
          </p>

          <Subheader>9. Miscellaneous</Subheader>
          <p>
            <b>9.1 Entire Agreement</b>
          </p>
          <p>
            These terms constitute the entire agreement between you and us with respect to the subject matter hereof.
            This Agreement supersedes any and all prior or contemporaneous written and oral agreements, communications
            and other understandings that you might believe are relating to the subject matter of the terms.
          </p>

          <p>
            <b>9.2 Assignment</b>
          </p>
          <p>
            You may not assign or transfer this Agreement, by operation of law or otherwise, without our prior written
            consent. Any attempt by you to assign or transfer this Agreement without our prior written consent shall be
            null and void. We may freely assign or transfer this Agreement. Subject to the foregoing, this Agreement
            will bind and inure to the benefit of the parties, their successors and permitted assigns.
          </p>

          <p>
            <b>9.3 Not Registered with the SEC or Any Other Agency</b>
          </p>
          <p>
            We are not registered with the U.S. Securities and Exchange Commission as a national securities exchange or
            in any other capacity. You understand and acknowledge that we do not broker trading orders on your behalf.
            We also do not facilitate the execution or settlement of your trades, which occur entirely on public
            distributed blockchains like Ethereum. As a result, we do not (and cannot) guarantee market best pricing or
            best execution through our Product. Any references in a Product to &quot;best price&quot; does not
            constitute a representation or warranty about pricing available through such Product, on the Protocol, or
            elsewhere.
          </p>
          <p>
            We are in the process of registering with the Central Bank of Bahrain (CBB) and applying for approval and
            entrance into the Regulatory Sandbox of the CBB&apos;s FinTech & Innovation Unit. Until we are accepted into
            the Sandbox or are licensed officially by the CBB, the Product and Protocol are not registered with any
            other regulatory agency.
          </p>

          <p>
            <b>9.4. Notice</b>
          </p>
          <p>
            We do not collect any personal data from you, Any notice that might be applicable to you would only be
            listed on this site or on one of many public communication channels. Notices we provide by using public
            communication channels will be effective upon posting.
          </p>

          <p>
            <b>9.5 Severability</b>
          </p>
          <p>
            If any provision of this Agreement shall be determined to be invalid or unenforceable under any rule, law,
            or regulation of any jurisdiction or government agency, such provision will be changed and interpreted to
            accomplish the objectives of the provision to the greatest extent possible under any applicable law and the
            validity or enforceability of any other provision of this Agreement shall not be affected.
          </p>
        </Paragraph>
      </Container>
    </PageWrapper>
  )
}
