import TokenLogoLookupTable from 'constants/TokenLogoLookupTable'
import { isCelo, nativeOnChain } from 'constants/tokens'
import { chainIdToNetworkName, getNativeLogoURI } from 'lib/hooks/useCurrencyLogoURIs'
import uriToHttp from 'lib/utils/uriToHttp'
import { useCallback, useEffect, useState } from 'react'
import { isAddress } from 'utils'

import celoLogo from '../assets/svg/celo_logo.svg'

const BAD_SRCS: { [tokenAddress: string]: true } = {}

// Converts uri's into fetchable urls
function parseLogoSources(uris: string[]) {
  const urls: string[] = []
  uris.forEach((uri) => urls.push(...uriToHttp(uri)))
  return urls
}

// Parses uri's, favors non-coingecko images, and improves coingecko logo quality
function prioritizeLogoSources(uris: string[]) {
  const parsedUris = uris.map((uri) => uriToHttp(uri)).flat(1)
  const preferredUris: string[] = []

  // Consolidate duplicate coingecko urls into one fallback source
  let coingeckoUrl: string | undefined = undefined

  parsedUris.forEach((uri) => {
    if (uri.startsWith('https://assets.coingecko')) {
      if (!coingeckoUrl) {
        coingeckoUrl = uri.replace(/small|thumb/g, 'large')
      }
    } else {
      preferredUris.push(uri)
    }
  })
  // Places coingecko urls in the back of the source array
  return coingeckoUrl ? [...preferredUris, coingeckoUrl] : preferredUris
}

function getGhHostedLogo(address?: string | null, chainId?: number | null, isNative?: boolean) {
  if (chainId && isNative) return getNativeLogoURI(chainId)

  const networkName = chainId ? chainIdToNetworkName(chainId) : 'ethereum'
  const checksummedAddress = isAddress(address)

  if (chainId && isCelo(chainId) && address === nativeOnChain(chainId).wrapped.address) {
    return celoLogo
  }

  if (checksummedAddress) {
    return `https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/${networkName}/assets/${checksummedAddress}/logo.png`
  } else {
    return undefined
  }
}

export default function useAssetLogoSource(
  address?: string | null,
  chainId?: number | null,
  isNative?: boolean,
  backupImg?: string | null
): [string | undefined, () => void] {
  const [current, setCurrent] = useState<string | undefined>(undefined)
  const [fallbackSrcs, setFallbackSrcs] = useState<string[] | undefined>(undefined)

  const initSrcs = useCallback(() => {
    const uris = TokenLogoLookupTable.getIcons(address, chainId) ?? []
    const ghHostedLogo = getGhHostedLogo(address, chainId, isNative)

    if (backupImg) {
      uris.push(backupImg)
    }

    const tokenListIcons = prioritizeLogoSources(parseLogoSources(uris))

    if (ghHostedLogo) {
      tokenListIcons.push(ghHostedLogo)
    }

    setCurrent(tokenListIcons.find((src) => !BAD_SRCS[src]))
    setFallbackSrcs(tokenListIcons)
  }, [address, backupImg, chainId, isNative])

  useEffect(() => {
    if (!fallbackSrcs) {
      initSrcs()
    }
  }, [fallbackSrcs, initSrcs])

  // reset state when address or chainId change
  useEffect(() => {
    if (address && chainId) {
      initSrcs()
    }
  }, [address, chainId, initSrcs])

  const nextSrc = useCallback(() => {
    if (current) {
      BAD_SRCS[current] = true
    }
    if (!fallbackSrcs) {
      initSrcs()
    } else {
      setCurrent(fallbackSrcs.find((src) => !BAD_SRCS[src]))
    }
  }, [current, fallbackSrcs, initSrcs])

  return [current, nextSrc]
}
