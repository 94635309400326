import { Trans } from '@lingui/macro'
import { TokenInfo } from '@uniswap/token-lists'
import { CHAIN_NAME_TO_CHAIN_ID, validateUrlChainParam } from 'graphql/data/util'
import React, { CSSProperties, ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { SupportedChainId } from '../../../constants/chains'
import { TOKEN_SHORTHANDS } from '../../../constants/tokens'
import { Chain } from '../../../graphql/data/__generated__/types-and-hooks'
import { useCombinedActiveList } from '../../../state/lists/hooks'
import { ThemedText } from '../../../theme'
import { LogoImage } from '../../Logo/AssetLogo'
import {
  LARGE_MEDIA_BREAKPOINT,
  MAX_WIDTH_MEDIA_BREAKPOINT,
  MEDIUM_MEDIA_BREAKPOINT,
  SMALL_MEDIA_BREAKPOINT,
} from '../constants'

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  background-color: ${({ theme }) => theme.backgroundSurface};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.backgroundOutline};
`

const TokenDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  width: 100%;
`

const NoTokenDisplay = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  padding: 0px 28px;
  gap: 8px;
`

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ListNumberCell = styled(Cell)<{ header: boolean }>`
  color: ${({ theme }) => theme.textSecondary};
  min-width: 32px;
  font-size: 14px;

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    display: none;
  }
`

const NameCell = styled(Cell)`
  justify-content: flex-start;
  padding: 0px 8px;
  min-width: 50px;
  gap: 8px;
`

const StyledTokenRow = styled.div<{
  first?: boolean
  last?: boolean
  $loading?: boolean
}>`
  background-color: transparent;
  display: grid;
  font-size: 16px;
  grid-template-columns: 1fr 4fr 7fr;
  line-height: 24px;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  min-width: 390px;
  ${({ first, last }) => css`
    height: ${first || last ? '72px' : '64px'};
    padding-top: ${first ? '8px' : '0px'};
    padding-bottom: ${last ? '8px' : '0px'};
  `}
  padding-left: 12px;
  padding-right: 12px;
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
  width: 100%;
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  &:hover {
    ${({ $loading, theme }) =>
      !$loading &&
      css`
        background-color: ${theme.hoverDefault};
      `}
    ${({ last }) =>
      last &&
      css`
        border-radius: 0px 0px 8px 8px;
      `}
  }

  @media only screen and (max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT}) {
    grid-template-columns: 1fr 4.5fr 6.5fr;
  }

  @media only screen and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    grid-template-columns: 1fr 4.5fr 7.5fr;
  }

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    grid-template-columns: 1fr 5fr 10fr;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    grid-template-columns: 120px 5fr;
    min-width: unset;
    border-bottom: 0.5px solid ${({ theme }) => theme.backgroundModule};

    :last-of-type {
      border-bottom: none;
    }
  }
`

const StyledHeaderRow = styled(StyledTokenRow)`
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.backgroundOutline};
  border-radius: 8px 8px 0px 0px;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 14px;
  height: 48px;
  line-height: 16px;
  padding: 0px 12px;
  width: 100%;
  justify-content: center;

  &:hover {
    background-color: transparent;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    justify-content: space-between;
  }
`

const StyledLink = styled.a`
  text-decoration: none;
`

const ClickableContent = styled.div`
  display: flex;
  text-decoration: none;
  color: ${({ theme }) => theme.textPrimary};
  align-items: center;
  cursor: pointer;
`

const ClickableName = styled(ClickableContent)`
  gap: 8px;
  max-width: 100%;
`

const TokenInfoCell = styled(Cell)`
  gap: 8px;
  line-height: 24px;
  font-size: 16px;
  max-width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    justify-content: flex-start;
    gap: 0px;
    width: max-content;
    font-weight: 500;
  }
`

const TokenName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`
const TokenSymbol = styled(Cell)`
  color: ${({ theme }) => theme.textTertiary};

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    height: 16px;
    justify-content: flex-start;
    width: 100%;
  }
`

function TokenRow({
  header,
  listNumber,
  tokenInfo,
  tokenSymbol,
  ...rest
}: {
  first?: boolean
  header: boolean
  listNumber: ReactNode
  $loading?: boolean
  tokenInfo: ReactNode
  tokenSymbol: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowCells: JSX.Element = (
    <>
      <ListNumberCell header={header}>{listNumber}</ListNumberCell>
      <NameCell>{tokenSymbol}</NameCell>
      <NameCell data-testid="name-cell">{tokenInfo}</NameCell>
    </>
  )
  if (header) return <StyledHeaderRow data-testid="header-row">{rowCells}</StyledHeaderRow>
  return <StyledTokenRow {...rest}>{rowCells}</StyledTokenRow>
}

/* Header Row: top header row component for table */
function HeaderRow(): JSX.Element {
  return (
    <TokenRow
      header={true}
      listNumber={<ThemedText.SubHeader>#</ThemedText.SubHeader>}
      tokenInfo={
        <ThemedText.SubHeader>
          <Trans>Token name</Trans>
        </ThemedText.SubHeader>
      }
      tokenSymbol={<ThemedText.SubHeader>Token symbol</ThemedText.SubHeader>}
    />
  )
}

function NoTokensState({ message }: { message: ReactNode }) {
  return (
    <GridContainer>
      <HeaderRow />
      <NoTokenDisplay>{message}</NoTokenDisplay>
    </GridContainer>
  )
}

type TokenDetails = {
  detailsUrl: string
} & Pick<TokenInfo, 'name' | 'logoURI' | 'symbol' | 'address'>

export default function TokensList(): JSX.Element {
  const chainName: Chain = validateUrlChainParam(useParams<{ chainName?: string }>().chainName)
  const chainId: SupportedChainId = CHAIN_NAME_TO_CHAIN_ID[chainName]
  const activeTokenList = useCombinedActiveList()

  const addresses: string[] = activeTokenList[chainId] ? Object.keys(activeTokenList[chainId]) : []

  const allTokenDetails: TokenDetails[] = addresses.map((address: string): TokenDetails => {
    const { token } = activeTokenList[chainId][address]
    return {
      address: token.tokenInfo.address,
      logoURI: token.tokenInfo.logoURI,
      name: token.tokenInfo.name,
      symbol: token.tokenInfo.symbol,
      detailsUrl: `https://sbt.dinari.com/tokens/${token.tokenInfo.symbol}`,
    }
  })

  // Filters out currency tokens from the token list
  const currencyTokenSymbols: string[] = Object.keys(TOKEN_SHORTHANDS).map((name: string) => name.toLowerCase())
  const assetTokenDetails: TokenDetails[] = allTokenDetails.filter((token: TokenDetails) => {
    return !currencyTokenSymbols.includes(token.symbol.toLowerCase())
  })

  if (assetTokenDetails?.length === 0) {
    return <NoTokensState message={<Trans>No tokens found</Trans>} />
  }

  // Sorts token list by token name
  assetTokenDetails.sort((a, b) => a.name.localeCompare(b.name))

  return (
    <GridContainer>
      <HeaderRow />
      <TokenDataContainer>
        {assetTokenDetails.map((token, index) => {
          return (
            <StyledLink key={token.address} href={token.detailsUrl}>
              <TokenRow
                header={false}
                listNumber={index + 1}
                tokenInfo={
                  <ClickableName>
                    <TokenInfoCell>
                      <TokenName data-cy="token-name">{token.name}</TokenName>
                    </TokenInfoCell>
                  </ClickableName>
                }
                tokenSymbol={
                  <ClickableName>
                    <TokenInfoCell>
                      <LogoImage style={{ marginRight: '6px' }} src={token.logoURI} size="24px" />
                      <TokenSymbol>{token.symbol}</TokenSymbol>
                    </TokenInfoCell>
                  </ClickableName>
                }
              />
            </StyledLink>
          )
        })}
      </TokenDataContainer>
    </GridContainer>
  )
}
